import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      feeds: [],
      loading: false,
      error: null,
      contributors:[],
      followedOrgs:[],
      allContributors:[],
      reactedFeed: {},
    };
  },
  actions,
  mutations,
  getters,
};
