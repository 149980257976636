export default {
  setInterests(state, payload) {
    state.interests = payload;
  },
  setFetchedLanguages(state, payload) {
    state.languages = payload;
  },
  setFeatures(state, payload) {
    state.features = payload;
  },
  setShareArticles(state, payload) {
    if (payload.results) {
      state.shareArticles = payload.results;
      state.shareArticleNextUrl = payload.next;
      state.shareArticlepreviousUrl = payload.previous;
      state.shareTotalArticles = payload.count;
    } else {
      state.shareArticles = payload;
    }
  },
  setDiscussionComments(state, payload) {
    state.topicComments = payload;
  },
  setRatedArticles(state, payload) {
    state.ratedArticles = payload.results;
    state.ratedArticleNextUrl = payload.next;
    state.ratedArticlepreviousUrl = payload.previous;
    state.ratedTotalArticles = payload.count;
  },
  setInterestGroup(state, payload) {
    state.interestGroup = [];
    payload.forEach((element) => {
      state.interestGroup.push(element);
      state.interestGroupId = element.id;
    });
  },
  clearInterestGroup(state) {
    state.interestGroup = [];
  },
  articleReaction(state, payload) {
    state.activeReactionId = payload;
    state.reactedArticle = payload;
  },
  setSavedArticles(state, payload) {
    state.savedArticles = payload;
  },
  setSaveArticleFolders(state, payload) {
    state.articlesFodlers = payload;
  },
  setJoinedSaveArticleFolders(state, payload) {
    state.joinedFolders = payload;
  },
};
