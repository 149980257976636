export default {
  CREATE_SESSION(state, payload) {
    state.sessions.push(payload);
  },

  SET_SESSION_ID_FOR_UPDATE(state, id) {
    state.sessionsIdForUpdate = id;
  },

  CREATE_CHAT(state, comment) {
    for (let i = 0; i < state.sessions.length; i++) {
      if (state.sessions[i].id == state.sessionIdForUpdate) {
        state.sessions.comments.push(comment);
        break;
      }
    }
  },

  EDIT_SESSION_NAME(state, title) {
    for (let i = 0; i < state.sessions.length; i++) {
      if (state.sessions[i].id == state.sessionIdForUpdate) {
        state.sessions[i].name = title;
        break;
      }
    }
  },

  DELETE_SESSION(state, id) {
    state.sessions = state.sessions.filter((session) => {
      if (session.id != id) {
        return session;
      }
    });
  },

  SET_ACTIVE_SESSION(state, session) {
    state.activeSession = session;
  },
  CLEAR_SESSION_CHAT(state) {
    state.sessionChat = [];
  },
  SET_SESSION_CHAT: (state, sessionChat) => { state.sessionChat = sessionChat },
  UPDATE_SESSION_CHAT: (state, chat) => { state.sessionChat.push(chat) },
  TOGGLE_CHAT_SETION_SKELETON: (state, toggle) => { state.chatSectionSkeleton = toggle; },
  TOGGLE_BOT_RESPONSE_LOADER: (state, toggledLoader) => state.botReponseLoader = toggledLoader,
  SESSION_DELETE: (state, payload) => state.showDeletSession = payload,
  CLEAR_SESSIONCHAT(state) {
    state.sessionChat = [];
  },
  SET_SEARCH_CHAT: (state, payload) => (state.searchChat = payload),
}