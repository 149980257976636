export default {
  SET_FEEDS(state, feeds) {
    state.feeds = feeds;
  },
  setFollowed(state, payload) {
    state.contributors = payload;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_FOLLOWED_ORGS(state, payload){
    state.followedOrgs = payload
  },
  SET_ALL_CONTRIBUTORS(state, payload){
    state.allContributors = payload
  },
  // feedReaction(state, payload) {
  //   state.feeds = [...state.feeds.map(feed => {
  //     if (feed.id === payload.id) {
  //       feed = { ...payload }
  //     }
  //     return feed
  //   })]
  // },
  feedReaction(state, payload) {
    const index = state.feeds.findIndex(feed => feed.id === payload.id);
    if (index !== -1) {
      state.feeds[index] = { ...payload };
    }
  }
  
};
