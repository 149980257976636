import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      sessions: [],
      sesionIdForUpdate: null,
      sessionSelected: false,
      latestSession: null,
      socket: null,
      activeSession: null,
      sessionChat: [],
      toggleToComments:false,
      chatSectionSkeleton: false,
      botReponseLoader:false,
      showDeletSession:false,
      searchChat: [],
    };
  },
  actions,
  mutations,
  getters,
};
